import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap'
import logo from '../assets/logo_bfd.png'
import '../App.css'

const Header = () => {

  return (
    <header>
      <Navbar bg="dark" variant="dark" expand="lg" collapseOnSelect>
        <Container>
          <LinkContainer to='/'>
            <Navbar.Brand><img src={logo} className="img-fluid" alt="BFD-" style={{ width: 60, height: 50}}></img>&nbsp;
            bellafinedesign.com</Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <LinkContainer to="/about">
                <Nav.Link >About</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/services">
                <Nav.Link href="#">Services</Nav.Link>
              </LinkContainer>
              <NavDropdown title="Sample-Sites" id="basic-nav-dropdown">
                  <NavDropdown.Item className="dditem" href="http://arise-and-shine-daycare.s3-website-us-east-1.amazonaws.com/" target="_blank" rel="noreferrer noopener">Daycare</NavDropdown.Item>
                  <hr className="dropdown-divider" />
                  <NavDropdown.Item className="dditem" href="https://www.sandrariggins.com" target="_blank" rel="noreferrer noopener">Freelancers</NavDropdown.Item>
                  <hr className="dropdown-divider" />
                  <NavDropdown.Item className="dditem" href="http://bellahomedesigns.s3-website-us-east-1.amazonaws.com/" target="_blank" rel="noreferrer noopener">Interior Design</NavDropdown.Item>
                  <hr className="dropdown-divider" />
                  <NavDropdown.Item className="dditem" href="http://srsplash.s3-website-us-east-1.amazonaws.com/" target="_blank" rel="noreferrer noopener">Splash Page</NavDropdown.Item>
                  <hr className="dropdown-divider" />
                  <NavDropdown.Item className="dditem" href="http://mywriterspage.s3-website-us-east-1.amazonaws.com/" target="_blank" rel="noreferrer noopener">Writers</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default Header
