import React, { Component } from 'react';

class Statement extends Component {

render() {

  return (
    <div className='text-center p-3 mb-2 bg-info'>
      <h1>A Great Idea Needs A Great Website!</h1>
    </div>
  )
  };
};

export default Statement;