import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import './App.css'
import About from './components/About.js'
import Header from './components/Header.js'
import Statement from './components/Statement.js'
import Webtypes from './components/Webtypes.js'
import Services from './components/Services'
import Connects from './components/Connect.js'
import background from './assets/alaska-vista.jpg'
import waves from './assets/waves.png'


function App() {
  return (
    <Router className="App">
      <Header />
      <main style={{backgroundImage: `url(${background})`, backgroundSize: '100% 100%'}}>
        <Container> 
          <Route path='/about' component={About} />
          <Route path='/services' component={Services} />
        </Container>
        <Statement />
        <Webtypes />
        <img className="text-center" src={waves} alt="waves" style={{ height: '250px', width: '100%', alignSelf: 'center'}}></img>
        <Connects />
      </main>
    </Router>
  );
}

export default App;
