import React from 'react';
import CONNECT_LINKS from './connectLinks';
import '../Styles.css'

const Connect = props => {
    const { link, image } = props.connectLinks;

    return (
      <span>
        <a href={link}><img src={image} alt='connect-with-me' style={{ width:30, height: 25, margin: 10 }} /></a>
      </span>
    )
}

const Connects = () => (
  <div className='connect text-center'>
    <h4>Sandra Riggins</h4>
    <h5>Web Developer</h5>
    <h5>Connect with me!</h5>
    <h6>Email: sandra@bellafinedesign.com</h6>
      <div>
        {
          CONNECT_LINKS.map(CONNECT_LINKS => {
            return <Connect key={CONNECT_LINKS.id} connectLinks={CONNECT_LINKS} />
          })
        }
      </div>
    <footer id="footer">
			<p className="connect-f">&copy; 2021 - All Rights Reserved.</p>
		</footer>
		
  </div>    
    )

export default Connects;