import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap'
import '../Styles.css'

class Services extends Component {

render() {

  return (
    <div>
      <Row className="row">
        <Col>
        <h4 className="text-center" style={{paddingTop: 10}}>Services</h4>
      </Col>
      </Row>
      <Row className="row">
        <Col className="colp border border-primary border-2">
          <h5>Package 1</h5>
          <ul>
            <li>Design</li>
            <li>Build</li>
            <li>Setup</li>
            <li>Training</li>
            <li>One Year Site Maintenance</li>
          </ul>
        </Col>
        <Col className="colp border border-primary border-2">
          <h5>Package 2</h5>
          <ul>
            <li>Build your pre-defined web design</li>
            <li>Setup</li>
            <li>Training</li>
            <li>One Year Site Maintenance</li>
          </ul>
        </Col>
        <Col className="colp border border-primary border-2">
          <h5>Package 3</h5>
          <ul>
            <li>Update/Add to your Website</li>
            <li>Training</li>
            <li>One Year Site Maintenance</li>
          </ul>
        </Col>
        </Row>
      </div>
  )
  };
};

export default Services;