import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import '../Styles.css'

class About extends Component {

render() {

  return (
    <div>
    <Row className='text-center'>
      <Col className='aboutcol'>I love working in the Technology Space!</Col>
    </Row>
    <Row className='text-center'>
      <Col className='aboutcol'>A custom website design will showcase your unique talents! </Col>
    </Row>
    </div>
  )
  };
};

export default About;