import React, { Component } from 'react';
import '../App.css'

class Webtypes extends Component {

render() {

  return (
    <div className="text-center" id="wtypes">
      <p>Designing Websites</p>
      <p id="sm">for</p>
      <p>Writers ~ Daycare ~ Freelancers ~ Entrepreneurs</p>
    </div>
  )
  };
};

export default Webtypes;