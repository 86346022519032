import emailIcon from '../assets/email_icon.png';
import linkedinIcon from '../assets/linkedin_icon.png';
import twitterIcon from '../assets/twitter_icon.png';

const CONNECT_LINKS = [
  {
    id: 1,
    link: 'https://www.linkedin.com/in/sandra-riggins',
    image: linkedinIcon
  },
  {
    id: 2,
    link: 'https://twitter.com/Stechlady',
    image: twitterIcon
  },
];

export default CONNECT_LINKS;